import { useCallback, useMemo, useRef } from "react";
import isEqual from "react-fast-compare";
import { useSearchParamsManager } from "~zipdeal-ui/query-filters/SearchParamsManager.tsx";

export function useZipSearchParam2(key) {
	const { setSearchParam, searchParams: _searchParams } =
		useSearchParamsManager()!;
	const previousValues = useRef();

	const setValue = useCallback(
		(value) => {
			setSearchParam(key, value);
		},
		[key, setSearchParam],
	);

	const deleteValue = useCallback(() => {
		setSearchParam(key, undefined);
	}, [key, setSearchParam]);

	const setValues = useCallback(
		(values) => {
			setSearchParam(key, values, "array");
		},
		[key, setSearchParam],
	);

	const value = _searchParams.get(key);
	const values = _searchParams.getAll(key);

	const memoizedValues = useMemo(() => {
		if (!isEqual(previousValues.current, values)) {
			previousValues.current = values;

			return values;
		}

		return previousValues.current;
	}, [values]);

	return useMemo(() => {
		return {
			value,
			values: memoizedValues,
			setValue,
			setValues,
			deleteValue,
		};
	}, [deleteValue, memoizedValues, setValue, setValues, value]);
}
